import { FunctionComponent, useEffect, useRef } from "react";
import Stats from "stats.js";

import { Provider } from "react-redux";
import { BrowserRouter, NavLink } from "react-router-dom";
import { Icon, Print, Tooltip } from "@ledr/instruments";
import ApiContext from "../../context/api";
import packageJson from '../../../../package.json';

import { Header } from "../../components/layout/DefaultLayout";
import { Account } from "../../hoc/login";

import Logo from "../../components/menu/logo96.png";
import PanelMenu from "./PanelMenu";

import "./App.scss";

interface MainAppProps {
	basename: string;
	overseer: any;
}

const MainApp: FunctionComponent<MainAppProps> = (props) => {

	const mountRef = useRef(null);

	useEffect(() => {

		const statsFps = new Stats();
		statsFps.showPanel(0); // Panel 0 = fps
		statsFps.dom.style.cssText = "";
		mountRef.current.appendChild(statsFps.dom);

		const statsMs = new Stats();
		statsMs.showPanel(1); // Panel 1 = ms
		statsMs.dom.style.cssText = "";
		mountRef.current.appendChild(statsMs.dom);

		const statsMb = new Stats();
		statsMb.showPanel(2); // Panel 2 = mb
		statsMb.dom.style.cssText = "";
		mountRef.current.appendChild(statsMb.dom);



		const animate = () => {
			requestAnimationFrame(animate);

			statsFps.update();
			statsMs.update();
			statsMb.update();
		};

		animate();

	}, [mountRef?.current])


	return (
		//@ts-ignore
		<ApiContext.Provider value={props.api}>
			<Provider store={props.overseer.store}>
				<BrowserRouter>
					<Header>
						<nav className={"navMenu"}>
							<NavLink to="/tuto" activeClassName={"selected"} exact>
								<img alt={"Ledr logo"} className={"logo"} src={Logo} />
							</NavLink>
							{/*<Search />*/}
							<div style={{
								marginTop:"7px",
								fontWeight:"bolder"
							}}>
								MAESTRO <br/>

							<div style={{
								fontWeight:"lighter"
							}}>
								{packageJson.version}
							</div>
							</div>
						</nav>
						<PanelMenu />

						<div style={{display: "flex", flexDirection: "row" }}>


							<div
								ref={mountRef}
								style={{ display: "flex" }}
							>
							</div>

							<a target="_blank" href="https://gitlab.com/ledr/core/dev-platform/Maestro/-/issues/new">
								<button
									style={{
										height: "80%",
										margin:"5px",
										color: "black",
										background: "red"
									}}
								>
									<Icon
										name={"AiFillBug"}
										size={33}
									/>
								</button>
							</a>

							<Account />

						</div>

					</Header>
				</BrowserRouter>
			</Provider>
		</ApiContext.Provider>
	);
};

export default MainApp;
