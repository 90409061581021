//@ts-ignore
import { notif } from "@ledr/layout";
import React, { FunctionComponent, useContext } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { newPanelAction } from "../../store/panels/actions";
import { AppState } from "../../store/types";
import { Icon, Tooltip } from "@ledr/instruments";
import { I_Panels } from "../../store/panels/reducer";
import ApiContext from "../../context/api";
import "./PanelMenu.scss";
import Avial from "@ledr/ts-client";

import { panelDeleteAction } from "../../store/panels/actions";

const SepMenu: FunctionComponent = () => {
  return (
    <div
      style={{
        width: "1px",
        margin: "5px 1px",
        backgroundColor: "grey",
      }}
    ></div>
  );
};

interface PanelButtonProps {
  panel: I_Panels;
}
const PanelButton: FunctionComponent<PanelButtonProps> = (props) => {
  const dispatch = useDispatch();

  return (
    <Tooltip
      style={{
        backgroundColor: "rgba(32, 32, 32, 0.9)",
        padding: "0px 0px 10px 0px",
      }}
      position={"bottom"}
			//@ts-ignore
      text={
        <div className={"panelTooTip"}>
          <div className={"winName"}> {props.panel.name} </div>
          <hr />
          <button>Save</button>
          <button
            onClick={() => {
              dispatch(panelDeleteAction(props.panel.id));
            }}
          >
            Delete
          </button>
        </div>
      }
    >
				{/*
				// @ts-ignore */}
      <NavLink
        key={props.panel.id}
        to={`/browser/${props.panel.id}`}
        activeClassName={"panelSelected"}
      >
        <button>
          <Icon name={props.panel.icon} size={30} />
        </button>
      </NavLink>
    </Tooltip>
  );
};

interface PanelMenuProps {}

const PanelMenu: FunctionComponent<PanelMenuProps> = (props) => {
  const panels = useSelector((state: AppState) => state.panels.panels);
  const dispatch = useDispatch();
  const newPanelHash = `TMP_${(Math.random() * 10000).toFixed(0).toString()}`;
  return (
    <nav className={"navMenu"} style={{ display: "flex" }}>
      {panels.map(
        (e, i) => !e.id.match(/TMP_[0-9]+/g) && <PanelButton key={i} panel={e} />
      )}
      <SepMenu />
      {panels.map(
        (e, i) => e.id.match(/TMP_[0-9]+/g) && <PanelButton key={i} panel={e} />
      )}
      {panels.find((e) => e.id.match(/TMP_[0-9]+/g)) && <SepMenu />}

      <NavLink
        onClick={() => dispatch(newPanelAction(newPanelHash))}
        to={`/browser/${newPanelHash}`}
        activeClassName={"selected"}
      >
        <button>
          <Icon name={"MdAddCircleOutline"} size={30} />
          <span className={"buttonTag"}>New Panel</span>
        </button>
      </NavLink>
    </nav>
  );
};

export default PanelMenu;
