//@ts-nocheck
import { FunctionComponent, useState } from "react";
import { NavLink } from "react-router-dom";
import Mask from "../utilities/Mask";
import { Icon } from "@ledr/instruments";
import packageJson from '../../../../package.json';

//import preval from "preval.macro";
import { User } from "../../store/reducers/settingsStore";
import "./Account.scss";
interface AccountMenuProps {
  user: User;
  onLogout: any;
}

const AccountMenu: FunctionComponent<AccountMenuProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className={"account"}>
      {/*
      <button>
				<Icon name={"IoNotificationsOutline"} size={30}/>
        <span className={"buttonTag"}>Notif</span>
      </button>
				*/}

      <button onClick={() => setIsOpen(true)}>
        <Icon name={"MdOutlineAccountCircle"} size={30} />
        <span className={`accountMenuSpanDecal buttonTag`}>Account</span>
      </button>

      {isOpen && (
        <>
          <Mask onClick={() => setIsOpen(false)} opacity={0.5} />

          <div className={`accountMenu shadow`}>
            <div className={"menuButton"}>
              <Icon
                name={"MdAccountCircle"}
                size={25}
                style={{ margin: "-3px 10px -6px 0px" }}
              />
              Hello <b>{props.user.firstName}</b> {props.user.lastName}
            </div>
            <NavLink
              to="/settings/account"
              className={"menuButton"}
              onClick={() => setIsOpen(false)}
            >
              <Icon
                name={"AiOutlineSetting"}
                size={25}
                style={{ margin: "-3px 10px -6px 0px" }}
              />
              Settings
            </NavLink>
            <div
              className="menuButton"
              onClick={() => document.body.requestFullscreen()}
            >
              <a>
                <Icon
                  name={"RiFullscreenFill"}
                  size={25}
                  style={{ margin: "-3px 10px -6px 0px" }}
                />
                Fullscreen
                {
                  //RiFullscreenExitFill
                }
              </a>
            </div>
            <NavLink
              to="/"
              className={"menuButton"}
              onClick={() => {
                setIsOpen(false);
                props.onLogout();
              }}
            >
              <Icon
                name={"AiOutlineLogout"}
                size={25}
                style={{ margin: "-3px 10px -6px 0px" }}
              />
              Logout
            </NavLink>

            <div className={"legal"}>
							Maestro <b>{packageJson.version}</b>
              <br />
              <b>Ledr</b> 2024
              {/*
              <b>Build</b>{" "}
								preval`
							let date =  new Date();
							module.exports = "" +
							date.getUTCFullYear() + "." +
							date.getUTCMonth() + "." +
							date.getUTCDate() + "." +
							date.getUTCHours() + "." +
							date.getUTCMinutes()+ "." +
							date.getUTCSeconds();
							`
              <b>UTC</b>
							*/}
            </div>
          </div>
        </>
      )}
    </nav>
  );
};

export default AccountMenu;
