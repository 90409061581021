import { FunctionComponent } from "react";
import "./Mask.scss";

interface MaskProps {
  onClick: () => void;
  opacity?: number;
}
const Mask: FunctionComponent<MaskProps> = (props) => {
  return (
    <div
      style={
        props.opacity
          ? { backgroundColor: `rgba(0, 0, 0, ${props.opacity}` }
          : null
      }
      onClick={props.onClick}
      className={"mask"}
    ></div>
  );
};

export default Mask;
